<template>
  <div class="flex items-center">
    <vs-avatar :src="url" class="flex-shrink-0 mr-2" size="50px" />
  </div>
</template>

<script>
export default {
  name: 'CellRendererLogo',
  computed: {
    url () {
      if (this.params.data.icon == 'features/default.png') {
        return this.params.data.avatar
      } else {
        return this.params.data.icon
      }

      // Below line will be for actual product
      // Currently it's commented due to demo purpose - Above url is for demo purpose
      // return "/apps/user/user-view/" + this.params.data.id
    }
  }
}
</script>
